import React from "react"

export default function PriceRangeCheckbox({ range, isSelected, onSelect }) {
  let [lowerBound, upperBound] = range
  let id = range.join("-")

  var label = ""
  if(lowerBound == 0) {
    label = `Under $${upperBound}`
  } else if(upperBound == Number.POSITIVE_INFINITY) {
    label = `$${lowerBound} & More`
  } else {
    label = `$${lowerBound}-$${upperBound}`
  }

  const onCheck = (e) => {
    e.target.checked ? onSelect(range) : onSelect(null)
  }

  return (
    <div className="uk-form price-range-checkbox">
      <input
        type="checkbox"
        className="price-range-checkbox__checkbox"
        id={id}
        checked={isSelected}
        name={label}
        onChange={onCheck}
      ></input>
      <label htmlFor={id}>{label}</label>
    </div>
  )
}
