import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimes as faTimesLight
} from "@fortawesome/pro-light-svg-icons"
import CollapsibleSection from "./AdvancedFilter/CollapsibleSection"
import TaxonCheckbox from "./AdvancedFilter/TaxonCheckbox"
import PriceRangeCheckbox from "./AdvancedFilter/PriceRangeCheckbox"
import PersonalizationCheckbox from "./AdvancedFilter/PersonalizationCheckbox"
import generateFilterQuery from "../../shared/util/generate_filter_query"

function FilterSvgIcon() {
  return (
    <svg className="custom-filter-svg-icon" id="FILTER-SVG-ICON" xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><rect x="2" y="2" width="16" height="2" rx="1.5" ry="1.5"/><rect x="4" y="7" width="12" height="2" rx="1.5" ry="1.5"/><rect x="6" y="12" width="8" height="2" rx="1.5" ry="1.5"/></svg>
  )
}

export default function AdvancedFilter({
    title,
    cardinalTaxon,
    primaryTaxons,
    isDisplayingDeepestChild,
    isDisplayingDiscoverTaxon,
    selectedTaxonIds: defaultSelectedTaxonIds,
    selectedDiscoverTaxonIds: defaultSelectedDiscoverTaxonIds,
    selectedPriceRange:  defaultSelectedPriceRange,
    personalizationMediums,
    additionalLinks,
    renderAsDropdown,
    discoverTaxons }) {
  if(defaultSelectedPriceRange && defaultSelectedPriceRange[1] == "Infinity") {
    defaultSelectedPriceRange[1] == Infinity
  }

  let [selectedDiscoverTaxonIds, setSelectedDiscoverTaxonIds] = useState(defaultSelectedDiscoverTaxonIds)
  let [selectedTaxonIds, setSelectedTaxonIds] = useState(defaultSelectedTaxonIds)
  let [selectedPersonalizationMediums, setSelectedPersonalizationMediums] = useState(personalizationMediums)
  let [isVisible, setIsVisible] = useState(!renderAsDropdown)
  let [selectedPriceRange, setSelectedPriceRange] = useState(defaultSelectedPriceRange)
  let anyPrimaryTaxonsHaveChildren =
    primaryTaxons.some(taxon => taxon.secondaryTaxons.length > 0)

  const areRangesEqual = (a, b) => {
    return (!!a && !!b) && (a[0] == b[0]) && (a[1] == b[1])
  }

  const addTaxonToSelection = (taxonId) => {
    setSelectedTaxonIds([...selectedTaxonIds, taxonId])
  }

  const removeTaxonFromSelection = (taxonId) => {
    setSelectedTaxonIds(selectedTaxonIds.filter((value) => value != taxonId))
  }

  const addDiscoverTaxonToSelection = (taxonId) => {
    setSelectedDiscoverTaxonIds([...selectedDiscoverTaxonIds, taxonId])
  }

  const removeDiscoverTaxonFromSelection = (taxonId) => {
    setSelectedDiscoverTaxonIds(selectedDiscoverTaxonIds.filter((value) => value != taxonId))
  }

  const addMediumToSelection = (medium) => {
    setSelectedPersonalizationMediums([...selectedPersonalizationMediums, medium])
  }

  const removeMediumFromSelection = (medium) => {
    setSelectedPersonalizationMediums(selectedPersonalizationMediums.filter((value) => value != medium))
  }

  const filterPage = () => {
    window.location.search = generateFilterQuery({
      taxonIds: selectedTaxonIds,
      discoverTaxonIds: selectedDiscoverTaxonIds,
      priceRange: selectedPriceRange,
      personalizationMediums: selectedPersonalizationMediums
    }).toString()
  }

  const priceRanges = [
    [0, 50],
    [50, 100],
    [100, 150],
    [150, Infinity]
  ]

  const cardinalTaxonIsShop =
    cardinalTaxon.permalink === "/shop"

  return (
    <>
      <button
          className="advanced-filter__mobile-button"
          onClick={() => setIsVisible(true)}>
        <p className="filter-button-text pw-font-weight-700 pw-text-s advanced-filter__mobile-button-text">Filters</p>
        <FilterSvgIcon/>
      </button>
      {isVisible && (
        <section className="advanced-filter">
          <p className="advanced-filter__header pw-font-weight-700">{title}</p>
          <p className="filter-subheader-margin advanced-filter__subheader advanced-filter__subheader--desktop pw-text-m"><FilterSvgIcon/>Filter</p>
          <div className="advanced-filter__subheader-container--mobile">
            <p className="advanced-filter__subheader pw-text-m pw-font-weight-700">Filter By</p>
            <button className="advanced-filter__close-button" onClick={() => setIsVisible(false)}>
                <FontAwesomeIcon icon={faTimesLight}/>
            </button>
          </div>
          {!cardinalTaxonIsShop && (
            <div className="filter-all pw-font-weight-700"><a href="/shop">All products</a></div>
          )}
          <div>
            <p className="filter-all pw-font-weight-700"><a href={cardinalTaxon.permalink}>All {cardinalTaxon.name}</a></p>
          </div>

          {anyPrimaryTaxonsHaveChildren && primaryTaxons.map((primaryTaxon, index) => (
            <CollapsibleSection label={primaryTaxon.name}
                link={primaryTaxon.permalink}
                defaultCollapsedState={index === 0 ? renderAsDropdown : !renderAsDropdown}
                key={primaryTaxon.id}>
              {primaryTaxon.secondaryTaxons.map((secondaryTaxon) => (
                <TaxonCheckbox taxon={secondaryTaxon}
                  disabled={isDisplayingDeepestChild}
                  isSelected={isDisplayingDeepestChild ? true : selectedTaxonIds.includes(secondaryTaxon.id)}
                  selectTaxon={addTaxonToSelection}
                  unselectTaxon={removeTaxonFromSelection}
                  key={secondaryTaxon.id}/>
              ))}
            </CollapsibleSection>
          ))}

          {!anyPrimaryTaxonsHaveChildren && primaryTaxons.map((primaryTaxon, index) => (
                <TaxonCheckbox taxon={primaryTaxon}
                  disabled={isDisplayingDeepestChild}
                  isSelected={isDisplayingDeepestChild ? true : selectedTaxonIds.includes(primaryTaxon.id)}
                  selectTaxon={addTaxonToSelection}
                  unselectTaxon={removeTaxonFromSelection}
                  key={primaryTaxon.id}/>
          ))}

          {additionalLinks.map(({ label, url}, idx) => (
            <p className="filter-all pw-font-weight-700" key={idx}>
              <a href={url}>{label}</a>
            </p>
          ))}

          <CollapsibleSection label={"Discover"}
              defaultCollapsedState={!renderAsDropdown}
              className="advanced-filter__discover-section">
            {discoverTaxons.map((discoverTaxon, index) => (
              <TaxonCheckbox taxon={discoverTaxon}
                disabled={isDisplayingDiscoverTaxon}
                isSelected={selectedDiscoverTaxonIds.includes(discoverTaxon.id) || isDisplayingDiscoverTaxon}
                selectTaxon={addDiscoverTaxonToSelection}
                unselectTaxon={removeDiscoverTaxonFromSelection}
                key={discoverTaxon.id}/>
            ))}
          </CollapsibleSection>


          <CollapsibleSection label={"Price Range"}
              defaultCollapsedState={!renderAsDropdown}
              className="advanced-filter__price-range-section">
            {priceRanges.map((range, idx) => (
              <PriceRangeCheckbox range={range}
                key={idx}
                isSelected={areRangesEqual(range, selectedPriceRange)}
                onSelect={setSelectedPriceRange}/>
            ))}
          </CollapsibleSection>

          <CollapsibleSection label={"Personalization"}
              defaultCollapsedState={!renderAsDropdown}
              className="advanced-filter__personalization-section">
            <PersonalizationCheckbox medium={"label"}
              isSelected={selectedPersonalizationMediums.includes("label")}
              selectMedium={addMediumToSelection}
              unselectMedium={removeMediumFromSelection}/>
            <PersonalizationCheckbox medium={"engraving"}
              isSelected={selectedPersonalizationMediums.includes("engraving")}
              selectMedium={addMediumToSelection}
              unselectMedium={removeMediumFromSelection}/>
          </CollapsibleSection>

          <div className="advanced-filter__apply-button-container">
            <div className="advanced-filter__apply-button-box-shadow">
              <button className="advanced-filter__apply-button" onClick={filterPage}>Apply</button>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
