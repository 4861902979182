import { Date as UIKitDate } from "../uikit/form/Date"

const CollectDateOfBirth = createReactClass({
  getInitialState() {
    return { dob: '' }
  },
  handleChange(event) {
    return this.setState({ dob: event.value })
  },
  handleSubmit(event) {
    event.preventDefault()
    const request = {
      type: 'PUT',
      url: `/api/users/${App.user.id}`,
      data: {
        user: { dob: this.state.dob }
      }
    };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      return this.props.callback('continue');
    });

  },
  render() {
    return (
      <div className="uk-container uk-container-center uk-padding">
        <div className="uk-text-center uk-margin-bottom-important uk-text-large uk-text-bold">
            Let's get started
        </div>
        <form className="uk-form uk-form-stacked" onSubmit={this.handleSubmit}>
          <label className="uk-form-label">
              Please enter your date of birth
          </label>
          <div className="uk-form-controls">
            <UIKitDate name="birthday"
              className="uk-width-1-1"
              onChange={this.handleChange}
              />
          </div>

          <button className="uk-button uk-margin uk-button-primary uk-width-1-1">Continue</button>
        </form>
    </div>
    )
  }
})

export default CollectDateOfBirth;
