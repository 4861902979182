import SignUpForm from "../../../components/SignUpForm";

const UserRegistrationDesign = createReactClass({
  getInitialState() {
    return {
        render_form: 'email',
        email: ''
    };
},

  continue_as_guest(event) {
    event.preventDefault();
    return this.props.callback('continue');
},

  continue_with_email(email) {
    this.setState({email});

    const request = {
      type: 'GET',
      url: "/api/user_registrations/exists",
      data: {
        "spree_user[email]": email
    }
  };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      if (data.user_exists) {
        return this.setState({render_form: 'sign_in'});
      } else {
        return this.setState({render_form: 'sign_up'});
    }
    });
},

  render() {
    let form;
    switch (this.state.render_form) {
      case 'email': form = <EmailForm continueWithEmail={this.continue_with_email}/>; break;
      case 'sign_in': form = <SignInForm email={this.state.email} callback={this.props.callback} />; break;
      case 'sign_up': form = <SignUpForm email={this.state.email} callback={this.props.callback}/>; break;
      default:
        form = false;
    }

    return <div className="uk-container uk-container-center uk-padding">
        <div className="uk-margin-large uk-text-center">
            <h2 className="uk-text-500 uk-margin-small-bottom">Save your design</h2>
            <p className="uk-margin-remove">
                View, edit, or clone your design any time from any device
            </p>
        </div>

        {form}

        {this.state.render_form === 'sign_in' &&
          <div className="uk-text-center uk-margin-medium-top-important uk-text-smaller">
            <a href="/password/recover" target="_blank">Forgot password?</a>
          </div>
        }
        <div className="uk-text-center uk-margin-medium-top-important">
            <a href="#" onClick={this.continue_as_guest}>
                Or, continue as a guest
            </a>
        </div>
    </div>;
}
});


var EmailForm = createReactClass({
  getInitialState() {
    return {
        forceValididations: false,
        email: null,
        valid: false
    };
},

  handleChange(event) {
    return this.setState({email: event.value, valid: event.valid});
},

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.valid) {
      this.setState({forceValidations: true});
      return;
  }

    return this.props.continueWithEmail(this.state.email);
},

  render() {
    return <UIKitForm className="uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Email
            </label>
            <div className="uk-form-controls">
                <UIKitInput.Email name="email" className="uk-width-1-1" onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-form-row">
            <span className="uk-form-danger" id="txtErrorMessage"></span>
        </div>

        <button className="uk-button uk-button-primary uk-width-1-1">
            Continue
        </button>
    </UIKitForm>;
}
});


var SignInForm = createReactClass({
  getInitialState() {
    return {
        forceValidations: false,
        form: {
          values: {},
          valids: {}
      }
    };
},

  handleChange(event) {
    const newForm = this.state.form;
    newForm.values[event.name] = event.value;
    newForm.valids[event.name] = event.valid;
    return this.setState({form: newForm});
},

  handleSubmit(event) {
    event.preventDefault();

    if (!(_.reduce(this.state.form.valids, ((memo, valid) => memo && valid), true))) {
      this.setState({forceValidations: true});
      return;
  }

    const request = {
      type: 'POST',
      url: "/api/user_registrations/signin",
      data: {
        "spree_user[email]": this.state.form.values.email,
        "spree_user[password]": this.state.form.values.password
    }
  };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      if (data.success) {
        App.setUserSignedIn(data.userId, data.userApiKey, data.orderNumber, data.orderToken);
        return this.props.callback('continue');
      } else {
        return this.setState({errorMessage: data.errors[0]});
    }
});
},

  render() {
    return <form className="uk-form uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
                <UIKitInput.Email name="email" className="uk-width-1-1" onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations} defaultValue={this.props.email}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
                <UIKitInput.Password name="password" className="uk-width-1-1" onChange={this.handleChange}
                                     forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-margin">
            <span className="uk-form-danger">{this.state.errorMessage}</span>
        </div>

        <button className="uk-button uk-button-primary uk-width-1-1">Sign in</button>
    </form>;
}
});

export default UserRegistrationDesign
