import React, { useEffect, createRef, useState } from "react"
import shave from 'shave'

// In order to server side render this component, we need to know the height
// it will be when the text is collapsed. We can't know the line height for sure
// on the server, so we use the value we currently know it is and hope it doesn't
// change to much.
// The purpose of server side rendering this component is to prevent content layout
// shifting when the component loads in, so even if the value is close it will still
// be useful for preventing the component from changing size.
const approximateLineHeight = 24;

export default function TextWithExpand({ text, maxLines }){
  const textElem = createRef(null)
  const [expanded, setExpanded] = useState(false);
  const [shaved, setShaved] = useState(false);

  useEffect(() => {
    const lineHeight = parseInt(window.getComputedStyle(textElem.current).lineHeight.replace('px', ''))
    // Shave is an external library that will split the text content into three span elements:
    // The visible text, the ellipsis character, and the truncated text (hidden by default)
    shave(textElem.current, lineHeight * maxLines, { character: " ...more" });

    setShaved(true)
  }, [expanded])

  const expand = () => {
    setExpanded(!expanded)
  }

  // We need to render the element on the server side (pre-shaving) with an
  // approximately correct height to prevent the element from causing content
  // layout shifting as it loads in.
  const preShavedSyle = {
    height: `${approximateLineHeight * maxLines}px`,
    overflow: "hidden"
  }

  return (
    // The `truncation-visible` class will trigger css rules that change the
    // visibility of the elements created by the call to the shave library.
    <div
      className={expanded ? "truncation-visible" : ""}
      style={shaved ? {} : preShavedSyle}
    >
      <span
        onClick={expand}
        ref={textElem}
      >
          {text}
      </span>
      {expanded && <span className="less-button">...less</span>}
    </div>
  )
}
