export class TagManager {

  static event(name) {
    if (dataLayer) { return dataLayer.push({'event': name}); }
  }

  static push(data) {
    if (dataLayer) { return dataLayer.push(data); }
  }
};

export function setupTagEvents() {
  PubSub.subscribe('product.impressions', function(message, options) {
    if (!dataLayer) { return; }

    return dataLayer.push({
      'event': 'ecommerceEvent',
      'ecommerce': {
        'currencyCode': 'USD',
        'impressions': _.map(options.products, product => GoogleAnalytics.impression(product))
      }
    });
  });

  PubSub.subscribe('order.add', function(message, options) {
    if (!dataLayer) { return; }

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    return dataLayer.push({
      'event': 'add_to_cart',
      'ecommerce': {
        'currency': 'USD',
        'items': [{
          'item_id': String(options.variant.id),
          'item_name': options.variant.name,
          'price': parseFloat(options.variant.price),
          'quantity': options.quantity
        }]
      }});
  });


  PubSub.subscribe('order.remove', function(message, options) {
    if (!dataLayer) {

      dataLayer.push({'event': 'removeFromCart'});
      dataLayer.push;
      return {
        'event': 'ecommerceEvent',
        'ecommerce': {
          'currencyCode': 'USD',
          'remove': {
            'products': [GoogleAnalytics.product(options.variant, {quantity: options.quantity})]
          }
        }
      };
    }
  });


  PubSub.subscribe('order.checkout', function(message, options) {
    if (!dataLayer) { return; }

    const products = _.map(options.order.line_items, function(item) {
      const coupon = _.reduce(item.adjustments, (function(memo, adjustment) {
        if (adjustment.eligible && adjustment.promotion_code) {
          if (memo) { return memo + "|" + adjustment.promotion_code.value; } else { return adjustment.promotion_code.value; }
        } else {
          return memo;
        }}), '');

      return GoogleAnalytics.product(item.variant, {quantity: item.quantity, coupon});
    });

    const checkoutStep = {address: 1, delivery: 2, payment: 3, confirm: 4};

    dataLayer.push({'event': 'checkout'});
    return dataLayer.push({
      'event': 'ecommerceEvent',
      'ecommerce': {
        'checkout': {
          'actionField': {
            'step': checkoutStep[options.order.state]
          },
          'products': products
        }
      }
    });
  });


  return PubSub.subscribe('order.purchase', function(message, options) {
    if (!dataLayer) { return; }

    const products = _.map(options.order.line_items, item => GoogleAnalytics.product(item.variant, {quantity: item.quantity}));
    const line_items = _.map(
      options.order.line_items,
      line_item => {
        return {
          item_id: String(line_item.id),
          item_name: line_item.variant.name,
          item_category: line_item.variant.ga_category,
          price: parseFloat(line_item.price),
          quantity: line_item.quantity
        }
      }
    );
    const code = _.reduce(options.order.all_adjustments, (function(memo, adjustment) {
      if (adjustment.eligible && adjustment.promotion_code) {
        if (memo) { return memo + "|" + adjustment.promotion_code.value; } else { return adjustment.promotion_code.value; }
      } else {
        return memo;
      }}), '');

    window.orderTotal = options.order.total + "";

    dataLayer.push({
      'event': 'purchase',
      'ecommerce': {
        'transaction_id': options.order.number,
        'value': options.order.total,
        'tax': options.order.tax_total,
        'shipping': options.order.ship_total,
        'currency': "USD",
        'coupon': code,
        'items': line_items
      },
      'orderTotal': options.order.total,
      'userEmail': options.order.email
    });

    return dataLayer.push({
      'event': 'ecommerceEvent',
      'orderTotal': options.order.total,
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': options.order.number,
            'revenue': options.order.total,
            'tax': options.order.tax_total,
            'shipping': options.order.ship_total,
            'coupon': code
          },
          'products': products
        }
      }
    });
  });
}
