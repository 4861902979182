var HeartDesign = createReactClass({
  getInitialState() {
    return {
      modal: false,
      render_url: '',
      id: ''
    };
  },

  open_modal(render_url, id) {
    return this.setState({modal: true, render_url, id});
  },

  close_modal() {
    return this.setState({modal: false});
  },

  render() {
    const {
      templates_for_display
    } = this.props;
    const {
      title
    } = this.props;
    const {
      user
    } = this.props;
    const {
      medium
    } = this.props;
    const {
      saved_template_design_ids
    } = this.props;
    const self = this;

    const columns = templates_for_display.map((templates, index) => {
                    return <HeartDesignColumn open_modal={(render_url, id) => self.open_modal(render_url, id)} key={index} designs={templates.designs} render_url={templates.designs.filter((template) => {return template.size === "square";})[0].render_url} user={user} saved_template_design_ids={saved_template_design_ids} />;
    });

    const container = <div className={"heart-design label_container " + (medium === 'label' ? "medium-label" : "")}>
                  <div className="uk-grid">
                    <div className="uk-width-medium-1-4"></div>

                    <h2 className="home_our_favorite_labels uk-width-small-1-1 uk-width-medium-2-4"><b>{title}</b></h2>

                    <div className="template_navigation_items uk-width-small-1-1 uk-width-medium-1-4">
                      <a href={medium === 'label' ? "/account/designs" : "/account/designs#account_engravings"} className="boxes_react_link favorites_box_link">
                        <div style={{fontSize: '28px', marginTop: '8px'}}>♥</div>
                        <div>Favorites</div>
                      </a>
                      <a href="/shop/wine" className="boxes_react_link boxes_box_link">
                        <div>
                          <img className="wines_icon" src="https://s3.amazonaws.com/file.personalwine.com/images/shared/wines_icon.png" style={{height: '32px', width: 'auto'}} />
                        </div>
                        <div>Wines</div>
                      </a>
                    </div>

                    <div className="heart_panel_seperator"></div>
                  </div>
                  <div className="label_sub_container">
                    {columns}
                    {this.state.modal == true ? <HeartDesignModal src={this.state.render_url} close_modal={this.close_modal} /> : "" }
                  </div>
                </div>;
    return container;
  }
});

var HeartDesignLinks = createReactClass({
  render() {
    return <div className="box_links">
      <p className="uk-visible-small" style={{color: 'red', margin: '0px', fontSize: '14px'}} onClick={() => this.props.open_modal(this.props.render_url ,this.props.id)}>See design again</p>
      <h5 style={{marginBottom: '0px', marginTop: '0px', lineHeight: 1}}>Now pick a wine:</h5>
      <a href="/shop/wine/red">Red</a>
      <a href="/shop/wine/white">White</a>
      <a href="/shop/wine/sparkling">Sparkling</a>
      <a href="/shop/wine/mini-bottles">Minis</a>
    </div>;
  }
})

var HeartDesignModal = createReactClass({
  closeModal() {
    return this.props.close_modal();
  },

  render() {

    return <div className="design_modal uk-text-center" onClick={this.closeModal}>
      <img src={this.props.src} />
    </div>;
  }
})

var HeartDesignColumn = createReactClass({
  getInitialState() {
    return {
      saved_template_designs: this.props.saved_template_design_ids,
      just_saved: false
    };
  },

  handleSubmit() {
    return this.save_and_replace_img();
  },

  save_and_replace_img() {
    let i=0;
    while (i <= (this.props.designs.length - 1)) {
      this.iterative_save(this.props.designs[i]);
      i++;
    }
    if (document.getElementById(this.props.designs[0].template_id)) {
      const img = document.getElementById(this.props.designs[0].template_id);
      img.setAttribute("class", 'shrink_box');
    }
    return this.setState({just_saved: true});
  },

  iterative_save(design) {
    const design_obj = {
      markup: JSON.parse(design.markup),
      full: design.render_url,
      size: design.size,
      template_id: design.template_id,
      medium: design.medium,
      source_id: design.id
    };
    const req = Api.Pervino.Design.create(design_obj, design_obj, this.props.user);
    return req.done(() => console.log('done, saved yo'));
  },

  do_nothing() {
    return alert('You have saved this to your library already');
  },

  open_modal(render_url) {
    return this.props.open_modal(render_url);
  },

  render() {
    let heart = '';
    const {
      render_url
    } = this.props;
    let already_saved = false;
    let j=0;
    const id = this.props.designs[0].template_id;
    while (j <= (this.state.saved_template_designs.length - 1)) {
      if (this.state.saved_template_designs[j] === this.props.designs[0].template_id) {
        already_saved = true;
      }
      j++;
    }
    if (this.state.just_saved === true) {
      heart = <div style={{ color: 'red' }} className="design_heart" onClick={this.do_nothing} >♥</div>;
    } else if (already_saved === false) {
      heart = <div style={{ color: 'black', cursor: "pointer" }} className="design_heart unsaved" onClick={this.handleSubmit} ></div>;
    } else {
      heart = <div style={{ color: 'red' }} className="design_heart" onClick={this.do_nothing} >♥</div>;
    }

    const column = <div className="column">
                  <div className="column_sub">
                    {heart}
                    <div onClick={() => this.open_modal(render_url, this.props.designs[0].template_id)} className="uk-text-center">
                      <img id={id} classes="display_design" src={render_url} height="1350" width="1350"/>
                    </div>
                    {this.state.just_saved ==  true ? <HeartDesignLinks open_modal={(render_url, id) => this.open_modal(render_url, id)} render_url={render_url} id={this.props.designs[0].template_id} /> : ''}
                  </div>
              </div>;
    return column;
  }
});

export default HeartDesign;
