import classNames from "classnames";

// UIKit Button wrapper
const UIKitButton = createReactClass({
	render() {
		let button;
		const cxClasses = {};
		cxClasses['uk-button'] = true;
		cxClasses[this.props.className] = !!this.props.className;

		const classes = classNames(cxClasses);

		return button = <button {...this.props} className={classes}>{this.props.children}</button>;
	}
});


export default UIKitButton;
