import React from "react"

export default function PersonalizationCheckbox({ medium, isSelected, selectMedium, unselectMedium}) {
  const formattedLabel = medium.charAt(0).toUpperCase() + medium.slice(1)

  const onMediumChecked = (checked, medium) => {
    if(checked) {
      selectMedium(medium)
    } else {
      unselectMedium(medium)
    }
  }

  return (
    <div className="uk-form personalization-checkbox">
      <input
        type="checkbox"
        className="personalization-checkbox__checkbox"
        id={medium}
        name={medium}
        checked={isSelected}
        onChange={(e) => onMediumChecked(e.target.checked, medium)}
      ></input>
      <label htmlFor={medium}>{formattedLabel}</label>
    </div>
  )
}
