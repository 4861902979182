import onDocumentReady from "./on_document_ready"

export default function setupSearchBar() {
  const setup = function (){
    let searchButton = document.getElementById("mobile-search-button")
    let searchBar = document.querySelector(".mobile-search-bar-container")

    searchButton.addEventListener('click', () => {
      if(searchBar.classList.contains('expanded')) {
        searchBar.classList.remove('expanded')
      } else {
        searchBar.classList.add('expanded')
      }
    })
  }

  onDocumentReady(setup)
}
