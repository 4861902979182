import InputMixin from "./InputMixin";

// UIKit Textarea wrapper
//
// @param {fn}       onChange - returns sythentic event
// @param {string}   [placeholder]

const UIKitTextarea = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return value && value.length;
      }
    };
  },

  render() {
    return <textarea  {...this.props} {...this.attributes()} />;
  }
});

export default UIKitTextarea
