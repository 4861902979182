import { fetchRecaptchaToken } from "shared/util/RecaptchaHelper"
export default class Messages {
  static async contact(requestJson, options) {
    return new Promise((resolve, _) => {
      fetchRecaptchaToken('contact').then((token) => {
        const recaptchaParams = {
          'g-recaptcha-response-data': {
            contact: token
          }
        }
        requestJson = { ...requestJson, ...recaptchaParams }

        let ajaxArgs = {
          type: 'POST',
          url: '/api/messages/contact',
          data: requestJson
        }

        Api.Spree.ajax(ajaxArgs, options).done((data) => {
          resolve(data)
        })
      })
    })
  }
};
