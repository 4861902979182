import classNames from "classnames";
import _ from "underscore"

const UIKitForm = createReactClass({
  getDefaultProps() {
    return {remote: true};
  },

  handleSubmit(event) {
    if (this.props.remote) {
      event.preventDefault();
      event.stopPropagation();
    }

    return this.props.onSubmit(event);
  },

  render() {
    const cxClasses = {};
    cxClasses['uk-form'] = true;
    cxClasses[this.props.className] = !!this.props.className;

    const classes = classNames(cxClasses);

    const divProps = _.omit(this.props, "remote");

    return <form {...divProps} className={classes} onSubmit={this.handleSubmit}>
        {this.props.children}
    </form>;
  }
});

export default UIKitForm;
