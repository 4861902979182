import InputMixin from "./InputMixin";
import _ from "underscore"

var UIKitInput = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {addValidationClasses: false};
  },

  render() {
    return <input {...this.divProps()} {...this.attributes()} />;
  }
});


UIKitInput.Text = createReactClass({
  mixins: [InputMixin],

  render() {
    return <input type="text" {...this.divProps()} {...this.attributes()} />;
  }
});


UIKitInput.RequiredText = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        if (value == null) { value = ""; }
        return value.length > 0;
      }
    };
  },

  render() {
    return <input type="text" {...this.divProps()} {...this.attributes()} />;
  }
});


UIKitInput.Password = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        if (value == null) { value = ""; }
        return value.length > 0;
      }
    };
  },

  render() {
    return <input type="password" {...this.props} {...this.attributes()} />;
  }
});


UIKitInput.Integer = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return !isNaN(value);
      },
      cleanInput(value) {
        return parseInt(value);
      }
    };
  },

  render() {
    return <input type="number" step="1" {...this.divProps()} {...this.attributes()} />;
  }
});


UIKitInput.Float = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return !isNaN(value);
      },
      cleanInput(value) {
        return parseFloat(value);
      }
    };
  },

  render() {
    return <input type="number" step="any" {...this.divProps()} {...this.attributes()} />;
  }
});


UIKitInput.Email = createReactClass({
  statics: {
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },

  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return value && value.length && UIKitInput.Email.validateEmail(value);
      }
    };
  },

  render() {
    return <input type="email" {...this.divProps()} {...this.attributes()} />;
  }
});

UIKitInput.Phone = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        if (value == null) { value = ''; }
        return value.length === 10;
      },

      cleanInput(value) {
        return value && value.replace(/\D/g, '').substring(0, 10);
      },

      displayValue(value) {
        if (!value) { return ''; }

        const formatValue = function(memo, digit, index, list) {
          if (index === (list.length - 1)) { return memo + digit; }

          switch (index) {
            case 2: return memo + digit + ') ';
            case 5: return memo + digit + '-';
            default:
              return memo + digit;
          }
        };

        return _.reduce(value.split(''), formatValue, '(');
      }
    };
  },

  componentWillMount() {
    if (this.props.defaultValue) { return this.setState({value: this.props.defaultValue}); }
  },

  render() {
    const displayValue = this.props.displayValue(this.state.value);
    const divProps = _.omit(this.divProps(), "defaultValue");

    return <input {...divProps} {...this.attributes()} value={displayValue}/>;
  }
});


UIKitInput.CreditCard = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return $.payment.validateCardNumber(value);
      }
    };
  },

  componentDidMount() {
    return $(this.input).payment('formatCardNumber');
  },

  render() {
    return <input type="text" {...this.divProps()} {...this.attributes()} ref={(input) => { this.input = input; }} />;
  }
});


UIKitInput.CVV = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {
      isValid(value) {
        return $.payment.validateCardCVC(value);
      }
    };
  },

  componentDidMount() {
    return $(this.input).payment('formatCardCVC');
  },

  render() {
    return <input {...this.divProps()} {...this.attributes()} ref={(input) => { this.input = input; }} />;
  }
});

export default UIKitInput;
