
import React, { useState, useEffect, useRef } from "react"

export default function GalleryPhotoUploadModal({ galleryPhotoId, modalQuerySelector }){
  const [uploadZone, setUploadZone] = useState(null);
  const uploadZoneElement = useRef(null)
  const progressZoneElement = useRef(null)
  const [fileSelectorDisabled, setFileSelectorDisabled] = useState(true);

  const initializeUploadZone = () => {
    if(uploadZone === null && uploadZoneElement.current) {
      const imageUploadCollection = new Backbone.Collection();

      setUploadZone(
        new Spree.Views.Images.UploadZone({
          el: uploadZoneElement.current,
          collection: imageUploadCollection
        })
      )

      listenForUpload(imageUploadCollection)

      setFileSelectorDisabled(false)
    }
  }

  const listenForUpload = (imageUploadCollection) => {
    imageUploadCollection.on('add', (progressModel) => {
      setFileSelectorDisabled(true)

      progressModel.set({variant_id: galleryPhotoId});

      var progressView = new Spree.Views.Images.UploadProgress({model: progressModel});
      progressZoneElement.current.appendChild(progressView.render().el);

      listenForUploadCompletion(progressModel)
    });
  }

  const listenForUploadCompletion = (imageUploadProgressModel) => {
    var completionListener = _.extend({}, Backbone.Events);
    completionListener.listenTo(imageUploadProgressModel, 'change:progress', (model) => {
      if(model.attributes.progress === 100){
        // Refresh the page to view the new gallery photo, and close the modal
        location.reload()
      }
    })
  }

  useEffect(() => {
    var modal = $(modalQuerySelector)

    if(modal.length) {
      modal.on('shown.bs.modal', () => {
        initializeUploadZone()
      })
    }
  });

  return (
    <fieldset className="no-border-bottom">
      <legend align="center">Upload Image</legend>

      <div ref={uploadZoneElement} className="gallery-upload-zone">
        <form>
          <input name="image_attachment" type="file" disabled={fileSelectorDisabled}/>
          <p>
            Or drag and drop it here
          </p>
        </form>

        <div ref={progressZoneElement} className="row progress-zone"></div>
      </div>
    </fieldset>
  )
}
