import React from "react"
import {
  faExternalLink as faExternalLinkLight,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function TaxonCheckbox({ taxon, disabled, isSelected, selectTaxon, unselectTaxon }) {
  const onTaxonCheckboxChecked = (checked, taxonId) => {
    if(checked) {
      selectTaxon(taxonId)
    } else {
      unselectTaxon(taxonId)
    }
  }

return (
  <div className="uk-form taxon-checkbox">
    <input
      type="checkbox"
      className="taxon-checkbox__checkbox"
      disabled={disabled}
      id={taxon.id}
      name={taxon.name}
      checked={isSelected}
      onChange={(e) => onTaxonCheckboxChecked(e.target.checked, taxon.id)}
    ></input>
    <label htmlFor={taxon.id}>
      <a href={taxon.permalink}>
        {taxon.name}
      </a>
    </label>
    {!disabled && (
      <a href={taxon.permalink}
          className="taxon-checkbox__link"
          title={`view all ${taxon.name.toLowerCase()} products`}>
        <FontAwesomeIcon icon={faExternalLinkLight}/>
      </a>
    )}
  </div>
)
}
