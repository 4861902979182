import SignUpForm from "../../../components/SignUpForm";
import CollectDateOfBirth from './CollectDateOfBirth';

const UserRegistrationCheckout = createReactClass({
  getInitialState() {
    return {
        render_form: App.user ? 'collect_dob' : 'email',
        email: ''
    };
},

  continue_with_email(email) {
    this.setState({email});

    const request = {
      type: 'GET',
      url: "/api/user_registrations/exists",
      data: {
        "spree_user[email]": email
    }
  };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      if (data.user_exists) {
        return this.setState({render_form: 'sign_in'});
      } else {
        return this.setState({render_form: 'sign_up'});
    }
    });
},

  handleBack(event) {
    event.stopPropagation();
    event.preventDefault();
    return this.setState({render_form: 'email'});
  },

  continueWithDateOfBirth() {
    this.setState({render_form: 'collect_dob'})
  },

  signInCallback(data) {
    if(data.collect_dob) {
      this.setState({render_form: 'collect_dob'})
    }
    else {
      this.props.callback('continue')
    }
  },

  render() {
    if (this.state.render_form === 'collect_dob') {
      return <CollectDateOfBirth callback={this.props.callback} />
    }
    let form;
    switch (this.state.render_form) {
      case 'email': form = <EmailForm continueWithEmail={this.continue_with_email}/>; break;
      case 'sign_in': form = <SignInForm email={this.state.email} callback={this.signInCallback} />; break;
      case 'sign_up': form = <SignUpForm email={this.state.email} callback={this.props.callback}>
        <div className="uk-margin-small-top">
          <button className="uk-button uk-button-small uk-width-1-1" onClick={this.handleBack}>
            Back
          </button>
        </div>
      </SignUpForm>; break;
      default:
        form = false;
    }

    return <div className="uk-container uk-container-center uk-padding">
        <div className="uk-text-center uk-margin-bottom-important uk-text-large uk-text-bold">
            Let's get started
        </div>

        {form}

        {this.state.render_form === 'sign_in' &&
          <div className="uk-text-center uk-margin-medium-top-important uk-text-smaller">
            <a href="/password/recover" target="_blank">Forgot password?</a>
          </div>
        }
        <div className="uk-text-center uk-margin-medium-top-important uk-text-smaller">
            <a href="/shop/wine">Or continue shopping</a>
        </div>
    </div>;
}
});


var EmailForm = createReactClass({
  getInitialState() {
    return {
        forceValididations: false,
        email: null,
        valid: false
    };
},

  handleChange(event) {
    return this.setState({email: event.value, valid: event.valid});
},

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.valid) {
      this.setState({forceValidations: true});
      return;
  }

    return this.props.continueWithEmail(this.state.email);
},

  render() {
    return <UIKitForm className="uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Email
            </label>
            <div className="uk-form-controls">
                <UIKitInput.Email name="email" className="uk-width-1-1" onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-form-row">
            <span className="uk-form-danger" id="txtErrorMessage"></span>
        </div>

        <button className="uk-button uk-button-primary uk-width-1-1">
            Submit
        </button>
    </UIKitForm>;
}
});


var SignInForm = createReactClass({
  getInitialState() {
    return {
        forceValidations: false,
        form: {
          values: {},
          valids: {}
      }
    };
},

  handleChange(event) {
    const newForm = this.state.form;
    newForm.values[event.name] = event.value;
    newForm.valids[event.name] = event.valid;
    return this.setState({form: newForm});
},

  handleSubmit(event) {
    event.preventDefault();

    if (!(_.reduce(this.state.form.valids, ((memo, valid) => memo && valid), true))) {
      this.setState({forceValidations: true});
      return;
  }

    const request = {
      type: 'POST',
      url: "/api/user_registrations/signin",
      data: {
        "spree_user[email]": this.state.form.values.email,
        "spree_user[password]": this.state.form.values.password
    }
  };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      if (data.success) {
        App.setUserSignedIn(data.userId, data.userApiKey, data.orderNumber, data.orderToken);
        return this.props.callback(data)
      } else {
        return this.setState({errorMessage: data.errors[0]});
    }
});
},

  render() {
    return <form className="uk-form uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
                <UIKitInput.Email name="email" className="uk-width-1-1" onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations} defaultValue={this.props.email}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
                <UIKitInput.Password name="password" className="uk-width-1-1" onChange={this.handleChange}
                                     forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-margin">
            <span className="uk-form-danger">{this.state.errorMessage}</span>
        </div>

        <button className="uk-button uk-button-primary uk-width-1-1">Sign in</button>
    </form>;
}
});

export default UserRegistrationCheckout
