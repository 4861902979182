import { BaseInput } from "./BaseInput"

export class Date extends React.Component {
  isValid(value) {
    return value != null && value.length > 0
  }

  render() {
    return <BaseInput type="date" required={true} isValid={this.isValid} {...this.props}/>
  }
}
