const DesignSelector = createReactClass({
  propTypes: {
    medium: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    options: PropTypes.object,
    defaultDesign: PropTypes.object
  },

  getDefaultProps() {
    return {options: {}};
  },

  render() {
    return <Component {...this.props} />;
  }
});

var Component = createReactClass({
  mixins: [LoadErrors, LoadTemplateDesigns, LoadDesigns, LoadTags],

  getInitialState() {
    return {mode: (this.props.options.tags && this.props.options.tags.length) ? 'template' : 'user'};
  },
  componentDidMount() {
    if (this.props.options.tags && this.props.options.tags.length) {
      this.setState({tags: this.props.options.tags});
    } else {
      this.loadTags(this.props.medium);
    }

    // This is needed so that non-display templates associated with hidden
    // tags will display when we are forced (in the case of partner pages).
    const forceDisplay = this.props.options.tags && (this.props.options.tags.length > 0);
    this.loadTemplateDesigns(this.props.medium, this.props.size, forceDisplay);
    return this.loadDesigns(this.props.size, this.props.medium, this.props.user_id, () => {
      // This is a race condition when tags are loaded async
      const allTags = _.map(this.state.tags, tag => tag.name);
      if (this.state.designs.length > 0) {
        return this.defaultTemplatesMode(allTags);
      } else {
        return this.templatesMode(allTags);
      }
    });
  },

  userMode() {
    return this.setState({mode: 'user'});
  },

  templatesMode(filters) {
    // This is needed so that non-display templates associated with hidden
    // tags will display when we are forced (in the case of partner pages).
    if (filters == null) { filters = []; }
    const forceDisplay = this.props.options.tags && (this.props.options.tags.length > 0);
    this.loadTemplateDesigns(this.props.medium, this.props.size, filters, forceDisplay);
    return this.setState({mode: 'template'});
  },

  defaultTemplatesMode(filters) {
    // This is needed so that non-display templates associated with hidden
    // tags will display when we are forced (in the case of partner pages).
    if (filters == null) { filters = []; }
    const forceDisplay = this.props.options.tags && (this.props.options.tags.length > 0);
    return this.loadTemplateDesigns(this.props.medium, this.props.size, filters, forceDisplay);
  },

  select(design) {
    return this.props.callback('select', design);
  },

  create(design) {
    return this.props.callback('create', design, this.props.options);
  },

  render() {
    let designs, loading, nextPage, title;
    const self = this;

    const actions = {
      select: this.select,
      create: this.create
    };

		// here's where we switch between user generated designs and templates
    if (this.state.mode === 'user') {
      ({
        designs
      } = this.state);
      loading = this.state.loadingDesigns;
      nextPage = this.nextDesignsPage;
    } else {
      designs = _.map(this.state.templateDesigns, templateDesign => templateDesign.design);
      loading = this.state.loadingTemplateDesigns;
      nextPage = this.nextTemplateDesignsPage;
    }

    if (this.props.medium === "label") {
      title = "Select a label";
    } else {
      title = "Select an engraving";
    }

    return <div className="uk-height-1-1">
      {/* MOBILE NAVIGATION */}
      <div className="uk-height-1-1 uk-visible-small">
        <div className="uk-flex uk-flex-column uk-height-1-1" ref="mobileScroll" style={{overflowY: "auto"}}>
          <div className="uk-flex-item-none">
            <MobileNavigation templatesMode={this.templatesMode}
              userMode={this.userMode}
              mode={this.state.mode}
              tags={this.state.tags} actions={actions} medium={this.props.medium}
              size={this.props.size}/>
          </div>
          <div className="uk-flex-item-none">
            <StartFromScratch actions={actions} medium={this.props.medium} size={this.props.size}/>
          </div>
          <div className="uk-flex-item-1 uk-flex">
            <DesignBrowser loading={loading}
              designs={designs}
              nextPage={nextPage}
              mode={this.state.mode}
              actions={actions}
              mobileScrollRef={this.refs['mobileScroll']}/>
          </div>
        </div>
      </div>

      {/* DESKTOP NAVIGATION */}
      <div className="uk-position-relative uk-height-1-1 uk-hidden-small">
        <div className="uk-position-absolute uk-overflow-container"
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            width: "232px",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.15)",
            zIndex: 99
          }}>
          <Navigation templatesMode={this.templatesMode}
            userMode={this.userMode}
            mode={this.state.mode}
            tags={this.state.tags}
            actions={actions}
            medium={this.props.medium}
            size={this.props.size}/>
        </div>
        <div className="uk-height-1-1"style={{marginLeft: "232px"}}>
          <DesignBrowser loading={loading}
            designs={designs}
            defaultDesign={this.props.defaultDesign}
            nextPage={nextPage}
            mode={this.state.mode}
            actions={actions}
            className="uk-overflow-container"/>
        </div>
      </div>
    </div>;
  }
});

var MobileNavigation = createReactClass({
  getInitialState() {
    return {activeTag: null};
  },

  handleChange(event) {
    if (event.target.value === "") {
      return this.all();
    } else {
      return this.select(event.target.value);
    }
  },

  select(tagName) {
    return this.setState({activeTag: tagName}, () => {
      return this.props.templatesMode([this.state.activeTag]);
  });
  },

  all() {
    if ((this.props.mode === 'template') && (this.state.activeTag === null)) { return; }

    return this.setState({activeTag: null}, () => {
      return this.props.templatesMode(_.map(this.props.tags, tag => tag.name)
      );
    });
  },

  mine() {
    return this.setState({activeTag: null}, () => {
      return this.props.userMode();
    });
  },

  render() {
    let tagSelect;
    const tags = _.sortBy(this.props.tags, tag => {
      return tag.name;
    });

    const tagOptions = _.map(tags, tag => {
      if (this.state.activeTag === tag.name) {
        return <option key={tag.id} value={tag.name} selected>{tag.name}</option>;
      } else {
        return <option key={tag.id} value={tag.name}>{tag.name}</option>;
      }
    });

    tagOptions.unshift(<option value="">Choose a category</option>);

    if (this.props.mode === "template") {
      tagSelect =
        <div className="uk-block uk-block-small uk-text-center" style={{padding: "10px 10px 0px 10px", backgroundColor: "white"}}>
          <div className="uk-input uk-input-small uk-width-1-1 uk-form-select uk-form-primary-inverse" data-uk-form-select>
            <span>Filter</span>
            <select onChange={this.handleChange}>{tagOptions}</select>
          </div>
        </div>;
    }

    const mineClasses = classNames("uk-width-4-9", {"uk-active": this.props.mode === 'user'});
    const templatesClasses = classNames("uk-width-4-9", {"uk-active": this.props.mode === 'template'});

    return <div className="uk-background-material">
      <nav className="uk-navbar uk-navbar-mobile">
        <ul className="uk-navbar-nav uk-width-1-1">
          <li className="uk-width-1-9" style={{backgroundColor: "white"}}>
            <a className="uk-modal-close uk-button-default uk-button" type="button" style={{marginRight: "25px", paddingLeft: "5px"}}>
              <i className="far fa-chevron-left uk-icon-small"></i>
            </a>
          </li>
          <li className={mineClasses}><a onClick={this.mine} href="javascript:void();">My Library</a>
          </li>
          <li className={templatesClasses}><a onClick={this.all} href="javascript:void();">Templates</a>
          </li>
        </ul>
      </nav>

      {tagSelect}
    </div>;
  }
});

var StartFromScratch = createReactClass({
  blank() {
    const request = Api.Spree.TemplateDesign.fetchblank(this.props.size, this.props.medium);
    return request.done(payload => {
      return this.props.actions.create(payload.design);
    });
  },

  render() {
    return <div className="uk-block uk-block-small uk-text-center" style={{padding: "10px"}}>
      <button className="uk-button uk-button-secondary uk-width-1-1" onClick={this.blank}> Start from scratch </button>
    </div>;
  }
});

var Navigation = createReactClass({
  getInitialState() {
    return {activeTags: []};
  },

  select(tagName) {
    return this.setState({activeTags: [tagName]}, () => {
      return this.props.templatesMode(this.state.activeTags);
    });
  },

  all() {
    if ((this.props.mode === 'template') && (this.state.activeTags.length === 0)) { return; }

    return this.setState({activeTags: []}, () => {
      return this.props.templatesMode(_.map(this.props.tags, tag => tag.name)
      );
    });
  },

  mine() {
    return this.setState({activeTags: []}, () => {
      return this.props.userMode();
    });
  },

  blank() {
    const request = Api.Spree.TemplateDesign.fetchblank(this.props.size, this.props.medium);
    return request.done(payload => {
      return this.props.actions.create(payload.design);
    });
  },

  render() {
    let tags = _.map(this.props.tags, tag => {
      return _.extend(tag, {active: (_.contains(this.state.activeTags, tag.name) && (this.props.mode === 'template'))});
    });

    tags = _.sortBy(tags, tag => {
      return tag.name;
    });

    tags = tags.map(tag => {
      const selectFn = this.select.bind(null, tag.name);
      return <NavigationItem key={tag.id} active={tag.active} onClick={selectFn}>
        {tag.name}
      </NavigationItem>;
    });

    return <div style={{padding: "16px 0"}}>
      <div className="material-list material-list-hoverable">
        <NavigationItem active={this.props.mode == 'user'} onClick={this.mine}>
          My library
          <div className="uk-text-small uk-text-400">Use or edit your previous designs</div>
        </NavigationItem>

        <NavigationItem onClick={this.blank}>
          Start from scratch
          <div className="uk-text-small uk-text-400">Add images and text to a blank canvas</div>
        </NavigationItem>

        <NavigationItem onClick={this.all}
            className="uk-border-top"
            active={this.state.activeTags.length == 0 && this.props.mode == 'template'}>
          Customize a template
          <div className="uk-text-small uk-text-400">Edit images and text on our templates</div>
        </NavigationItem>
      </div>

      <div className="material-list material-list-hoverable material-list-condensed material-list-submenu">
        {tags}
      </div>
    </div>;
  }
});

var NavigationItem = createReactClass({
  render() {
    let classes = {};
    classes['material-list-item'] = true;
    classes['material-list-item-active'] = this.props.active;
    classes[this.props.className] = this.props.className;

    classes = classNames(classes);

    return <div className={classes} onClick={this.props.onClick}>
      {this.props.children}
    </div>;
  }
});

const TagSearch = createReactClass({
  handleSelect(event, data, acobject) {
    return this.props.toggle(data.value);
  },

  render() {
    return false;

    const autocompleteSource = this.props.tags.map(tag => ({
      value: tag.name,
      title: tag.name,
      url: '#',
      text: ''
    }));

    const autocompleteConfiguration = {source: autocompleteSource};
    const inputProps = {placeholder: 'Search tags', className: "uk-form-small"};

    return <UIKitAutocomplete {...this.props} configuration={autocompleteConfiguration}
        selectItem={this.handleSelect}
        inputProps={inputProps}/>;
  }
});

export default DesignSelector
