import PropTypes from 'prop-types';
import React from "react"
import classNames from "classnames"

export class BaseInput extends React.Component {
  static defaultProps = {
    forceValidations: false,
    validationClasses: {
      success: {
        enabled: false,
        class: 'uk-form-success'
      },
      danger: {
        enabled: true,
        class: 'uk-form-danger'
      }
    }
  }

  // Initialize state right in the class body,
  // with a property initializer:
  state = {
    value: "",
    altered: false
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentWillMount() {
    // Simulate a change event
    return this.handleChange({
      target: {
        value: (this.props.value || this.props.defaultValue)
      }
    });
  }

  addValidationClasses() {
    if (this.props.addValidationClasses) {
      return this.props.addValidationClasses;
    } else {
      return true;
    }
  }

  isValid(value) {
    if (this.props.isValid) {
      return this.props.isValid(value);
    } else {
      return true;
    }
  }

  cleanInput(value) {
    if (this.props.cleanInput) {
      return this.props.cleanInput(value);
    } else {
      return value;
    }
  }

  validationClass() {
    if ((!this.state.altered && !this.props.forceValidations) || !this.addValidationClasses()) { return; }

    if (this.isValid(this.state.value)) {
      if (this.props.validationClasses.success.enabled) { return this.props.validationClasses.success.class; }
    } else if (!this.isValid(this.state.value)) {
      if (this.props.validationClasses.danger.enabled) { return this.props.validationClasses.danger.class; }
    }
  }

  classes() {
    return classNames(this.props.className, this.validationClass());
  }

  handleBlur() {
    return this.setState({
      altered: true});
  }

  handleChange(event) {
    const value = this.cleanInput(event.target.value);

    this.setState({
      value});

    return this.props.onChange({
      value,
      name: this.props.name,
      valid: this.isValid(value)
    });
  }

  divProps() {
    return _.omit(this.props, ["isValid", "forceValidations", "validationClasses", "addValidationClasses", "cleanInput", "isValid","onChange"]);
  }

  render () {
    return <input className={this.classes()}
      onChange={this.handleChange}
      onInput={this.handleChange}
      onPaste={this.handleChange}
      onBlur={this.handleBlur}
      {... this.divProps()} />;
  }
}

BaseInput.propTypes = {
  isValid: PropTypes.func,
  addValidationClasses: PropTypes.bool,
  cleanInput: PropTypes.func,
  onChange: PropTypes.func
}
