import InputMixin from "./InputMixin";
import classNames from "classnames";

// UIKit Form-Select wrapper
//
// @param {Object[]} options
// @param {string}   options[].label
// @param {string}   options[].value
// @param {fn}       onChange - returns updated value (not event)
// @param {string}   [placeholder]
var UIKitSelect = createReactClass({
  mixins: [InputMixin],

  getDefaultProps() {
    return {disabled: false};
  },

//  componentDidMount: ->
//    @listener = setInterval (=>
//      if @state.value != $(this.selectInput).val()
//        $(this.selectInput).trigger('change')), 50
//
//  componentWillUnmount: ->
//    clearInterval @listener

  componentWillUpdate() {
    // Force an update when the value changes programmatically
    // so that the UIKit component recognizes the change
    return $(this.selectInput).trigger('change');
  },

  render() {
    const cxClasses = {};
    cxClasses['uk-form-select'] = true;
    cxClasses['uk-text-muted'] = !this.state.value;
    cxClasses['uk-input-disabled'] = this.props.disabled;
    cxClasses['selectWrapper'] = true;

    const classes = classNames(cxClasses, this.classes());

    const options = this.props.options.map(option => {
      return <option key={option.value + '-' + option.label} value={option.value}>{option.label}</option>;
    });

    if (this.props.blank || (this.props.blank === '')) {
      options.unshift(<option key='blank' value=''>{this.props.blank}</option>);
    }

    return <div className={classes} data-uk-form-select>
        <span></span>

        <select id={this.props.id} className="selectBox" ref={(selectInput) => { this.selectInput = selectInput; }}  onChange={this.handleChange} defaultValue={this.props.defaultValue} autoComplete="off">
            {options}
        </select>
    </div>;
  }
});


UIKitSelect.Month = createReactClass({
  getDefaultProps() {
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => ({
      value: month,
      label: month
    }));

    return {
      options: months,
      isValid(value) {
        if (value == null) { value = ''; }
        return value !== '';
      },
      cleanInput(value) {
        if (value) { return value.replace(/^0/, ''); } else { return value; }
      }
    };
  },

  render() {
    return <UIKitSelect {...this.props} />;
  }
});


UIKitSelect.Year = createReactClass({
  getDefaultProps() {
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear + 20;
    const years = __range__(thisYear, lastYear, true).map(year => ({
      value: year,
      label: year
    }));

    return {
      options: years,
      isValid(value) {
        if (value == null) { value = ''; }
        return value !== '';
      }
    };
  },

  render() {
    return <UIKitSelect {...this.props} />;
  }
});

function __range__(left, right, inclusive) {
  let range = [];
  let ascending = left < right;
  let end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}

export default UIKitSelect;
