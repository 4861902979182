export default (function() {
  let defaultOptions = undefined;
  const AjaxRequest = class AjaxRequest {
    static initClass() {

      defaultOptions = {
        notification: {
          enabled: true,
          loading: true,
          error: true,
          success: true
        }
      };
    }

    constructor(options) {
      this.settings = $.extend({}, defaultOptions, options);
    }
  };
  AjaxRequest.initClass();
  return AjaxRequest;
})();
