export default function generateFilterQuery({
  taxonIds = [],
  discoverTaxonIds = [],
  priceRange = null,
  personalizationMediums = []
} = {}) {
  let queryParams = new URLSearchParams(window.location.search);

  queryParams.set('filtered_child_taxon_ids', taxonIds.join(","));
  queryParams.set('discover_taxon_ids', discoverTaxonIds.join(","));

  if(priceRange && priceRange[0] != 0){
    queryParams.set('costs_more_than', priceRange[0])
  } else {
    queryParams.delete('costs_more_than')
  }

  if(priceRange && priceRange[1] != Infinity){
    queryParams.set('costs_less_than', priceRange[1])
  } else {
    queryParams.delete('costs_less_than')
  }

  queryParams.set('personalization_mediums', personalizationMediums.join(","));

  return queryParams
}
