/**
 * The purpose of this class is to expose react classes loaded via webpack to
 * react classes loaded via the asset pipeline and react-rails. React classes
 * loaded by the asset pipeline are all set as globals on the window, so if any
 * classes there depend on a webpack class, we need to expose the webpack class
 * to the window also.
 */
import { Order } from "frontend/application/Order"
import AjaxRequestFailure from "frontend/application/AjaxRequestFailure"
import DesignBrowser from "shared/components/routine/DesignBrowser"
import DesignSelector from "shared/components/routine/DesignSelector"
import UserRegistrationCheckout from "shared/components/routine/UserRegistrationCheckout"
import UserRegistrationDesign from "shared/components/routine/UserRegistrationDesign"
import { Zipcode } from "./shared/components/uikit/form/Zipcode"
import { TagManager, setupTagEvents } from "./shared/util/TagManager"
import UIKitSelect from "./components/shared/uikit/form/UIKitSelect"
import UIKitInput from "./components/shared/uikit/form/UIKitInput"
import InputMixin from "./components/shared/uikit/form/InputMixin"
import UIKitForm from "./components/shared/uikit/form/UIKitForm"
import UIKitButton from "./components/shared/uikit/form/UIKitButton"
import CartFormSelect from "./components/frontend/CartFormSelect"

export function setupReactClassGlobals() {
  window.AjaxRequestFailure = AjaxRequestFailure
  window.DesignBrowser = DesignBrowser

  window.App = window.App || {};
  window.App.Order = Order

  window.Routine = window.Routine || {};
  window.Routine.DesignSelector = DesignSelector
  window.Routine.UserRegistrationCheckout = UserRegistrationCheckout
  window.Routine.UserRegistrationDesign = UserRegistrationDesign

  window.InputMixin = InputMixin
  window.UIKitInput = UIKitInput || {}
  window.UIKitInput.Zipcode = Zipcode
  window.UIKitSelect = UIKitSelect;
  window.UIKitForm = UIKitForm
  window.UIKitButton = UIKitButton

  window.TagManager = TagManager

  window.CartFormSelect = CartFormSelect

  $(document).ready(function() {
    setupTagEvents()
  });
}
