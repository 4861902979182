import React from "react"
import UIKitSelect from "../shared/uikit/form/UIKitSelect"

export default function TaxonSorter() {
  let queryParams = new URLSearchParams(window.location.search);

  let initialSortBy = queryParams.get("sort_by")
  if(initialSortBy == null || initialSortBy.trim() === "") {
    initialSortBy = "default"
  }

  const onSortSelect = ({ value }) => {
    if(value === "default") {
      value = ""

      if(queryParams.get("sort_by") === null){
        return;
      }
    }

    if(queryParams.get("sort_by") != value){
      queryParams.set("sort_by", value)
      window.location.search = queryParams.toString()
    }
  }

  return (<div className="uk-form taxon-sorter">
    <div className="uk-form-row">
      <div className="uk-form-controls">
      <UIKitSelect onChange={onSortSelect}
        id="taxon-sorter"
        className="uk-input uk-width-1-1"
        defaultValue={initialSortBy}
        options={[
          {
            label: "Default",
            value: "default"
          },
          {
            label: "A-Z",
            value: "NAME_ASC"
          },
          {
            label: "Low To High",
            value: "PRICE_ASC"
          },
          {
            label: "High To Low",
            value: "PRICE_DESC"
          }
        ]}/>
      </div>
    </div>
  </div>)
}
