import React, { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown as faChevronDownLight,
  faChevronUp as faChevronUpLight,
} from "@fortawesome/pro-light-svg-icons"
import classNames from "classnames"

export default function CollapsibleSection({ label, link, children, defaultCollapsedState, className}) {
  let collapseSectionRef = useRef()
  let [isCollapsed, setIsCollapsed] = useState(defaultCollapsedState)

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed)
  }

  const childIsSelected =
    React.Children.map(children, (child) => !!child.props.isSelected).some(isSelected => isSelected)

  useEffect(() => {
    if (childIsSelected) { setIsCollapsed(false) }
  }, [childIsSelected])

  useEffect(() => {
    if(isCollapsed) {
      collapseSectionRef.current.style.height = `0px`
    } else {
      // In order to animate the height of an element, we need to give
      // it an absolute value. This calculates the height of the collapsed
      // section based on the height of the children so it can be animated
      // to the appropriate size.
      let calculatedHeight = Array.from(collapseSectionRef.current.children)
        .reduce((total, elem) => total + elem.clientHeight, 0)
      collapseSectionRef.current.style.height = `${calculatedHeight}px`
    }
  }, [isCollapsed])

  return (
    <div className={classNames("collapsible-section", className)}>
      <div className="collapsible-section__title">
        {
          link ?
            (
              <p className="filter-option pw-font-weight-700">
                <a href={link}>{label}</a>
              </p>
            ) : <p className="filter-option pw-font-weight-700" onClick={() => toggleCollapsed()}>{label}</p>
        }
        {children.length > 0 && (
            <button className="collapsible-section__collapse" onClick={() => toggleCollapsed()}>
              {isCollapsed ?
                <FontAwesomeIcon icon={faChevronDownLight} /> :
                <FontAwesomeIcon icon={faChevronUpLight} />
            }
            </button>
          )}
      </div>
      <div className="collapsible-section__children" ref={collapseSectionRef}>
        {children}
      </div>
    </div>
  )
}
