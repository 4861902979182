import "../polyfill"
import { setupReactClassGlobals } from "../setup_react_class_globals"
import setupSearchBar from "../shared/util/mobile_search_bar"

var ctx = require.context("../components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(ctx)
import(/* webpackPreload: true */ "../icons")

setupReactClassGlobals()
setupSearchBar()
