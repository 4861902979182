import React, { createContext, useEffect, useRef, useState } from "react"
import { FocusOn } from "react-focus-on"
import classnames from "classnames"

const sizes = {
  md: "max-w-screen-md",
  lg: "max-w-screen-lg",
  xl: "max-w-screen-xl"
}

export const ModalContext = createContext({ portalId: null })

const Modal = ({
  children,
  enabled = true,
  onClose,
  size = "lg",
  focusOnProps = {}
}) => {
  /*
   * Inside the focus trap for this modal we render a div with a ref on it
   * that is passed down via context to children that want to render things
   * at the "root" (to escape z-index, etc) but need to be within the focus trap
   * (so body won't work). We want this ref to be defined by the time the children
   * render so we want until the modal is rendered before rendering it's children.
   */
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  // Pass a reference to a DOM node at the bottom of the modal focus
  // area. Useful for escaping z-index but staying within the focus area.
  const portalRef = useRef()
  const portalId = useRef(
    "modal_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
  )

  focusOnProps = { ...focusOnProps, enabled }

  if (onClose) {
    focusOnProps = {
      ...focusOnProps,
      onClickOutside: onClose,
      onEscapeKey: onClose
    }
  }

  const classes = classnames(
    "z-10 shadow-lg w-full h-full",
    "sm:container sm:mx-auto sm:w-11/12 sm:h-auto",
    sizes[size]
  )

  return (
    <ModalContext.Provider value={{ portalId: portalId.current, portalRef }}>
      <div
        className={classnames(
          "modal z-50 fixed inset-0 flex items-center justify-center",
          { hidden: !enabled }
        )}
        style={{ zIndex: 1025 }}
      >
        <div className="absolute inset-0 h-full w-full bg-gray-700 opacity-75"></div>
        <div className={classes} style={{ zIndex: 999 }}>
          <FocusOn {...focusOnProps} className="h-full w-full">
            <div
              className={classnames(
                "rm-modal-content h-full w-full overflow-y-auto bg-white sm:rounded"
              )}
            >
              {isRendered && children}
            </div>
            <div id={portalId.current} ref={portalRef} />
          </FocusOn>
        </div>
      </div>
    </ModalContext.Provider>
  )
}

export default Modal
