import React, { useEffect, useState } from "react"
import cx from "classnames"

export const Hidable = ({ className, hidden, ...rest }) => (
  <div className={cx(hidden && "hidden", className)} {...rest} />
)

export const Spinner = ({ label }) => (
  <div className="h-full w-full flex flex-col items-center justify-center p-5">
    <div className="ball-grid-container">
      <div className="ball-grid-pulse bg-gray-500">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
    {label && (
      <div className="mt-5 opacity-50 text-lg font-bold text-center">
        {label}
      </div>
    )}
  </div>
)

export const LoadingContainer = ({ isLoading, label, size, ...rest }) => (
  <>
    {isLoading && <Spinner size={size} label={label} />}
    <Hidable hidden={isLoading} {...rest} />
  </>
)

export const LoadingImage = ({ label = "Loading image", src, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (src) {
      setLoaded(false)

      const img = new Image()
      img.onload = () => setLoaded(true)
      img.src = src
    }
  }, [src])

  return loaded ? <img src={src} {...rest} /> : <Spinner label={label} />
}
