import Messages from "shared/api/pervino/Messages"
import UIKitTextarea from "../shared/uikit/form/UIKitTextarea";

const ContactForm = createReactClass({
  statics: {
    initialState() {
      return {
        forceValidations: false,
        form: {
          values: {
            name: '',
            email: '',
            message: ''
          },
          valids: {}
        }
      };
    }
  },

  getInitialState() {
    return {
      formVersion: 0,
      forceValidations: false,
      form: {
        values: {
          name: '',
          email: '',
          message: ''
        },
        valids: {}
      }
    };
  },

  handleChange(event) {
    const updateForm = this.state.form;
    updateForm.values[event.name] = event.value;
    updateForm.valids[event.name] = event.valid;
    return this.setState({form: updateForm});
  },

  async handleSubmit(event) {
    if (!(_.reduce(this.state.form.valids, ((memo, valid) => memo && valid), true))) {
      this.setState({forceValidations: true});
      return;
    }

    await Messages.contact({contact: this.state.form.values});

    return this.setState({
      formVersion: this.state.formVersion + 1,
      forceValidations: false
    });
  },

  render() {
    return <UIKitForm key={this.state.formVersion} className="uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label" for="name">Name</label>

            <div className="uk-form-controls">
                <UIKitInput.Text id="name" name='name' style={{width: "300px"}} onChange={this.handleChange}
                                 forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-form-row">
            <label className="uk-form-label" for="email">Email</label>

            <div className="uk-form-controls">
                <UIKitInput.Email id="email" name='email' style={{width: "300px"}} onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-form-row">
            <label className="uk-form-label" for="message">Message</label>

            <div className="uk-form-controls">
                <UIKitTextarea id="message" name='message' style={{width: "600px"}} rows="5" onChange={this.handleChange}
                               forceValidations={this.state.forceValidations}/>
            </div>
        </div>

        <div className="uk-margin-medium-top-important">
            <button type="submit" className="uk-button uk-button-large uk-button-primary">Send Message</button>
        </div>
    </UIKitForm>;
  }
});

export default ContactForm;
