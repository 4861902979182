import React from "react"
import { BaseInput } from "./BaseInput"

const pureLettersAndDashesPattern = /^[A-Za-z-]+$/

export class Zipcode extends React.Component {
  isValid(value) {
    if (value == null) { value = ''; }
    return (value.length > 0) && (value.length <= 10) && !value.match(pureLettersAndDashesPattern);
  }

  cleanInput(value) {
    return value && value.replace(/[^\dA-Za-z-]/g, '')
  }

  render() {
    return <BaseInput isValid={this.isValid} cleanInput={this.cleanInput} {...this.props}/>
  }
}
