import classNames from "classnames"
import { getDimensionsFromSize } from "../../util/DesignSizes";
import ImageWithPlaceholder from "../../../components/ImageWithPlaceholder";

const DesignBrowser = createReactClass({
  render() {
    let content;
    if (this.props.loading && (this.props.designs.length === 0)) {
      content = <UltimateSpinner />;
    } else {
      if (this.props.designs.length === 0) {
        content =
          <div className="uk-text-center uk-margin uk-height-1-1 uk-flex uk-flex-center uk-flex-middle uk-animation-fade">
            You haven't created any designs.
          </div>;
      } else {
        content =
          <div className="uk-height-1-1 uk-position-relative uk-animation-fade">
            <Grid {...this.props} />
            <Loading loading={this.props.loading}/>
          </div>;
      }
    }

    return <div className="uk-height-1-1 uk-width-1-1 uk-background-material">
      {content}
    </div>;
  }
});

var UltimateSpinner = createReactClass({
  render() {
    return <div className="uk-height-1-1 uk-width-1-1 uk-text-center uk-flex uk-flex-center uk-flex-middle uk-animation-fade">
      <div className="loading-wine-medium uk-align-center"></div>
    </div>;
  }
});

var Grid = createReactClass({
  componentDidMount() {
    const self = this;

    function scrollHandler(event) {
      const target = $(event.target)
      const scrollBarPosition = target.scrollTop()
      const scrollContainerHeight = target.innerHeight()
      const scrollBarTotalHeight = target.prop('scrollHeight')
      // We use `preloadOffset` to start loading the next page before the user
      // is at the bottom of the current page. E.g `preloadOffset = 600` means
      // the next page will load when the user is within 300px of the bottom of
      // the current page. We use 30% of the scroll height container to keep
      // this responsive.
      const preloadOffset = scrollContainerHeight / 3;
      if ((scrollBarPosition + scrollContainerHeight ) >= (scrollBarTotalHeight - preloadOffset)) {
        self.props.nextPage();
      }
    }

    // If this component is being rendered on mobile, the scroll container is
    // moved outside of this component. As such we must use the scroll
    // container passed in as a ref.
    if(this.props.mobileScrollRef) {
      $(ReactDOM.findDOMNode(this.props.mobileScrollRef)).on('scroll', scrollHandler);
    }
    else {
      $(ReactDOM.findDOMNode(this.refs['cont'])).on('scroll', scrollHandler);
    }
  },

  render() {
    // If a default design is present, we don't end up rendering the GridItem
    // components anyways so we can just return the first one so that the
    // design editor doesn't render multiple times.
    const designArray = this.props.defaultDesign == null ? this.props.designs : [this.props.designs[0]];
    const designs = designArray.map(design => {
      return <GridItem key={design.id} design={design} defaultDesign={this.props.defaultDesign} actions={this.props.actions} mode={this.props.mode}/>;
    });

    return <div ref='cont' className={classNames('uk-height-1-1', this.props.className)}>
      <div className="uk-container uk-container-center" style={{paddingTop: "9px", paddingBttom: "9px"}}>
        <div className="uk-grid uk-grid-small" data-uk-grid-margin>
          {designs}
        </div>
      </div>
    </div>;
  }
});

var GridItem = createReactClass({
  getInitialState() {
    return {mouseOver: false};
  },

  handleCustomize(event) {
    const isIphone = /(iPhone)/i.test(window.navigator.userAgent);
    if (isIphone) {
      document.body.style.position = 'fixed';
    }
    event.preventDefault();
    event.stopPropagation();
    return this.props.actions.create(this.props.design);
  },

  handleSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    return this.props.actions.select(this.props.design);
  },

  handleMouseOver(event) {
    event.preventDefault();
    event.stopPropagation();
    return this.setState({mouseOver: true});
  },

  handleMouseOut(event) {
    event.preventDefault();
    event.stopPropagation();
    return this.setState({mouseOver: false});
  },

  render() {
    let classes, defaultAction, details;

    // A default design being present means we want to skip the template
    // library popup and go straight to the editor with the given defaultDesign
    if (this.props.defaultDesign) {
      this.props.actions.create(this.props.defaultDesign);
    }


    if (this.state.mouseOver === true) {
      if (this.props.mode === 'user') {
        defaultAction = this.handleSelect;
        details = <UserCardDetails handleCustomize={this.handleCustomize} handleSelect={this.handleSelect}/>;
      } else {
        defaultAction = this.handleCustomize;
      }
    }

    const widthClass = (window.innerWidth >= 768) ? "uk-width-medium-1-2" : "uk-width-medium-1-3 pw-width-override-50";

    if (this.props.design.size === "bordeaux") {
      classes = widthClass + " uk-width-large-1-3 uk-width-xlarge-1-4 uk-grid-margin";
    } else if (this.props.design.size === "teardrop") {
      classes = widthClass + " uk-width-large-1-3 uk-width-xlarge-1-4 uk-grid-margin";
    } else {
      classes = "uk-width-1-2 uk-width-medium-1-3 uk-width-large-1-4 uk-width-xlarge-1-5";
    }

    let [image_width, image_height] = getDimensionsFromSize(this.props.design.size)

    return <div className={classes}>
      <UIKitCard className="uk-shadow-1" onClick={defaultAction} onMouseEnter={this.handleMouseOver}
                  onMouseLeave={this.handleMouseOut}>
        {details}
        <ImageWithPlaceholder src={this.props.design.rendering.medium} width={image_width} height={image_height}/>
      </UIKitCard>
    </div>;
  }
});

var UserCardDetails = createReactClass({
  render() {
    return <div className="pw-sheet-protector uk-flex uk-flex-center uk-flex-middle">
      <div className="pw-sheet-overlay">
      </div>
      <div style={{zIndex: 1000}}>
        <div>
          <button className="uk-button uk-button-large pw-button-primary-transparent pw-button-wide uk-margin-medium-bottom"
            onClick={this.props.handleSelect} >Use
          </button>
        </div>
        <div>
          <button className="uk-button uk-button-large pw-button-primary-transparent pw-button-wide"
            onClick={this.props.handleCustomize} >Edit
          </button>
        </div>
      </div>
    </div>;
  }
});

var Loading = createReactClass({
  render() {
    if (!this.props.loading) { return false; }

    const style = {
      bottom: 0,
      left: 0,
      right: 0,
      padding: '15px',
      background: 'rgba(255, 255, 255, .8)'
    };

    return <div className="uk-animation-fade uk-position-absolute" style={style}>
      <div className="uk-text-muted uk-text-center">
        <div className="loading-wine-medium uk-align-center"></div>
        <span className="uk-text-blocked uk-text-600" style={{marginLeft: "15px"}}>loading more</span>
      </div>
    </div>;
  }
});

export default DesignBrowser;
