import { Date as UIKitDate } from "../shared/components/uikit/form/Date"

var SignUpForm = createReactClass({
  getInitialState() {
    return {
        forceValidations: false,
        form: {
          values: {},
          valids: {}
      }
    };
  },

  handleChange({name, value, valid}) {
    const newForm = this.state.form;
    newForm.values[name] = value;
    newForm.valids[name] = valid;
    return this.setState({form: newForm});
  },

  handleSubmit(event) {
    event.preventDefault();
    $('#txtErrorMessage').text('');

    if (!(_.reduce(this.state.form.valids, ((memo, valid) => memo && valid), true))) {
      this.setState({forceValidations: true});
      return;
    }

    const request = {
      type: 'POST',
      url: "/api/user_registrations/signup",
      data: {
        "spree_user[email]": this.state.form.values.email,
        "spree_user[first_name]": this.state.form.values.first_name,
        "spree_user[last_name]": this.state.form.values.last_name,
        "spree_user[dob]": this.state.form.values.birthday,
        "spree_user[password]": this.state.form.values.password,
        "spree_user[password_confirmation]": this.state.form.values.password_confirmation,
        "spree_user[receive_promotions]": this.state.form.values.receive_promotions
      }
    };

    const req = Api.Spree.ajax(request);
    return req.done(data => {
      if (data.success) {
        App.setUserSignedIn(data.userId, data.userApiKey, data.orderNumber, data.orderToken);
        return this.props.callback('continue');
      } else {
        return this.setState({errorMessage: data.errors[0]});
      }
    });
  },

  render() {
    return <form className="uk-form uk-form-stacked" onSubmit={this.handleSubmit}>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Email
            </label>
            <div className="uk-form-controls">
                <UIKitInput.Email name="email" className="uk-width-1-1" onChange={this.handleChange}
                                  forceValidations={this.state.forceValidations} defaultValue={this.props.email}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">
                First Name
            </label>
            <div className="uk-form-controls">
                <UIKitInput.RequiredText name="first_name" className="uk-width-1-1" onChange={this.handleChange}
                                 forceValidations={this.state.forceValidations}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Last Name
            </label>
            <div className="uk-form-controls">
                <UIKitInput.RequiredText name="last_name" className="uk-width-1-1" onChange={this.handleChange}
                                 forceValidations={this.state.forceValidations}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Birthday
            </label>
            <div className="uk-form-controls">
              <UIKitDate name="birthday"
                className="uk-width-1-1"
                onChange={this.handleChange}
                forceValidations={this.state.forceValidations}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Password
            </label>
            <div className="uk-form-controls">
                <UIKitInput.Password name="password" className="uk-width-1-1" onChange={this.handleChange}
                                     forceValidations={this.state.forceValidations}/>
            </div>
        </div>
        <div className="uk-form-row">
            <label className="uk-form-label">
                Password Confirmation
            </label>
            <div className="uk-form-controls">
                <UIKitInput.Password name="password_confirmation" className="uk-width-1-1" onChange={this.handleChange}
                                     forceValidations={this.state.forceValidations}/>
            </div>
        </div>
        <div className="uk-form-row">
            <div className="uk-form-controls">
                <label className="uk-form-label">
                    <Checkbox name="receive_promotions" onChange={this.handleChange} defaultChecked/>
                    Occasionally send me exclusive promotional offers
                </label>
            </div>
        </div>

        <div className="uk-margin">
            <span className="uk-form-danger">{this.state.errorMessage}</span>
        </div>

        <button className="uk-button uk-button-primary uk-width-1-1" onClick={this.signup}>Sign Up</button>

        {this.props.children}
    </form>;
  }
});

export default SignUpForm
