import AjaxRequest from "./AjaxRequest"

export default (function() {
  let defaultParser = undefined;
  let default404Parser = undefined;
  let default422Parser = undefined;
  let default0Parser = undefined;
  let defaultOptions = undefined;
  const AjaxRequestFailure = class AjaxRequestFailure extends AjaxRequest {
    static initClass() {

      defaultParser = function() {
        if (this.jqXHR.responseJSON && this.jqXHR.responseJSON.errors) {
          return parseErrors(this.jqXHR.responseJSON.errors);
        } else {
          return Pervino.Util.Notifier.danger('An error occured, please give us a call and we\'ll be happy to help.');
        }
      };

      default404Parser = () => Pervino.Util.Notifier.danger('Route not found');

      default422Parser = function() {
        if (this.jqXHR.responseJSON.errors) {
          console.log(this.jqXHR.responseJSON);
          return parseErrors(this.jqXHR.responseJSON);
        } else if (this.jqXHR.responseJSON.error || this.jqXHR.responseJSON.exception) {
          let errMsg;
          Pervino.Util.Notifier.danger(this.jqXHR.responseJSON.error || this.jqXHR.responseJSON.exception);
          return errMsg = this.jqXHR.responseJSON.error || this.jqXHR.responseJSON.exception;
        }
      };

      default0Parser = function() {
      };

      defaultOptions = {
        error: {
          parser: {
            default: defaultParser,
            402: default404Parser,
            422: default422Parser,
            0: default0Parser
          },
          messages: {
            default: 'An error occurred. Call us at (512) 476-9463 if problem persists.'
          }
        }
      };
    }

    constructor(jqXHR, textStatus, errorThrown, options) {
      super($.extend({}, defaultOptions, options));
      this.jqXHR = jqXHR;
      if (!this.settings.notification.enabled || !this.settings.notification.error) { return; }
      (this.settings.error.parser[this.jqXHR.status.toString()] || this.settings.error.parser.default).call(this);
    }
  };
  AjaxRequestFailure.initClass();
  return AjaxRequestFailure;
})();

const personalizeError = function(rootError, errorKey, errorMessage) {
  const attributeName = errorKey.slice(errorKey.indexOf(".") + 1, errorKey.length);
  switch (attributeName) {
    case "zipcode":
      return "ZIP code " + errorMessage + ". " + rootError;
    case "base":
      return rootError + " " + errorMessage;
    default:
      let capitalizedAttributeName = attributeName.charAt(0).toUpperCase() + attributeName.slice(1);
      return [rootError, capitalizedAttributeName, errorMessage].join(" ") + "."
  }
};

var parseErrors = function(data) {
  const innerErrors = data.errors;
  const rootError = data.error;

  if (Array.isArray(innerErrors)) {
    return _.each(innerErrors, error => Pervino.Util.Notifier.danger(error));
  } else {
    for (const [errorKey, errorMessage] of Object.entries(innerErrors)) {
      if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage.join(", ");
      }

      const message = personalizeError(rootError, errorKey, errorMessage);

      return Pervino.Util.Notifier.danger(`${message}`);
    }
  }
};
